.modal {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(128, 128, 128, 0.7);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-box {
  position: absolute;
  top: '50%';
  left: '50%';
  transform: 'translate(-50%, -50%)';
  max-height: 95vh;
  max-width: 95vw;
  overflow: auto;
  background-color: #fff;
  border-radius: 0.25rem;
  padding: 1.5rem;
}
.modal-items--wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.modal-title--holder {
  display: flex;
  flex-grow: 1;
}
.icon-wrapper {
  cursor: pointer;
  border-radius: 99.99%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-wrapper:hover {
  background-color: rgba(128, 128, 128, 0.1);
}
.modal-data {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.modal-title {
  font-family: YekanBakh;
  color: #152738;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
}
.modal-logo--wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
}
.modal-text--holder {
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
}
.logo-wrapper {
  width: 4.25rem;
  height: 4.25rem;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.logo-holder svg {
  width: 1.875rem;
  height: 1.875rem;
}
/* helper classes  */
.orange {
  background-color: #fef0e2;
  color: #fe774f;
}
.green {
  background-color: #e6faf4;
  color: #00c98d;
}
.blue {
  background-color: #e6f7fe;
  color: #0077b6;
}
