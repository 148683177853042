.loading-container {
  width: 250px;
  height: 250px;
  position: absolute;
  top: calc(50% - 125px);
  left: calc(50% - 125px);
}
.rect {
  height: 100%;
  width: 100%;
  fill: transparent;
}
.firstLeftWing {
  animation: firstLeftWing 1s ease-in-out infinite;
}
.firstRightWing {
  animation: firstRightWing 1s ease-in-out infinite;
}
.secondLeftWing {
  animation: secondLeftWing 1s ease-in-out infinite;
}
.thirdLeftWing {
  animation: thirdLeftWing 1s ease-in-out infinite;
}
.secondRightWing {
  animation: SecondRightWingAnimate 1s ease-in-out infinite;
}

@keyframes firstLeftWing {
  0% {
    fill: #a1acbf;
  }
  10% {
    fill: #0077b6;
  }
  20% {
    fill: #0077b6;
  }
  30% {
    fill: #0077b6;
  }
  40% {
    fill: #0077b6;
  }
  50% {
    fill: #0077b6;
  }
  60% {
    fill: #a1acbf;
  }
  70% {
    fill: #a1acbf;
  }
  80% {
    fill: #a1acbf;
  }
  90% {
    fill: #a1acbf;
  }
}

@keyframes firstRightWing {
  0% {
    fill: #d1dbe8;
  }
  10% {
    fill: #d1dbe8;
  }
  20% {
    fill: #00b8eb;
  }
  30% {
    fill: #00b8eb;
  }
  40% {
    fill: #00b8eb;
  }
  50% {
    fill: #00b8eb;
  }
  60% {
    fill: #00b8eb;
  }
  70% {
    fill: #d1dbe8;
  }
  80% {
    fill: #d1dbe8;
  }
  90% {
    fill: #d1dbe8;
  }
}
@keyframes secondLeftWing {
  0% {
    fill: #d1dbe8;
  }
  10% {
    fill: #d1dbe8;
  }
  20% {
    fill: #d1dbe8;
  }
  30% {
    fill: #00b8eb;
  }
  40% {
    fill: #00b8eb;
  }
  50% {
    fill: #00b8eb;
  }
  60% {
    fill: #00b8eb;
  }
  70% {
    fill: #00b8eb;
  }
  80% {
    fill: #d1dbe8;
  }
  90% {
    fill: #d1dbe8;
  }
}
@keyframes thirdLeftWing {
  0% {
    fill: #d1dbe8;
  }
  10% {
    fill: #d1dbe8;
  }
  20% {
    fill: #d1dbe8;
  }
  30% {
    fill: #d1dbe8;
  }
  40% {
    fill: #00b8eb;
  }
  50% {
    fill: #00b8eb;
  }
  60% {
    fill: #00b8eb;
  }
  70% {
    fill: #00b8eb;
  }
  80% {
    fill: #00b8eb;
  }
  90% {
    fill: #d1dbe8;
  }
}
@keyframes SecondRightWingAnimate {
  0% {
    fill: #a1acbf;
  }
  10% {
    fill: #a1acbf;
  }
  20% {
    fill: #a1acbf;
  }
  30% {
    fill: #a1acbf;
  }
  40% {
    fill: #a1acbf;
  }
  50% {
    fill: #0077b6;
  }
  60% {
    fill: #0077b6;
  }
  70% {
    fill: #0077b6;
  }
  80% {
    fill: #0077b6;
  }
  90% {
    fill: #0077b6;
  }
}
