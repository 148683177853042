h1,
h2,
h5,
button {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
.errors-wrapper {
  display: flex;
  /* width: 100vw;
  height: 100vh; */
  margin-top: 15rem;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: YekanBakh;
}
.error-img--wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
}
.error-alert--wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1.75rem;
}
.error-alert-text {
  font-weight: 900;
  line-height: 1.75rem;
}
.error-title {
  font-weight: 900;
  line-height: 2.5rem;
  font-size: 1.75rem;
}
.error-text--wrapper {
  display: flex;
  width: 100%;
  margin-top: 1rem;
  justify-content: center;
}
.error-text {
  font-weight: 700;
  line-height: 1.25rem;
}
.error-btn--wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 3.25rem;
}
.error-btn {
  background-color: #0077b6;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 0.25rem;
  min-width: 8.125rem;
  padding: 0.75rem 1.125rem;
  font-size: 0.875rem;
  font-weight: 900;
  line-height: 1rem;
  color: #fff;
  cursor: pointer;
}
.error-btn:hover {
  background-color: #00537f;
}
